// this file is prettier-ignored as its styling has been manually tailored to be
// more readable, please do not blindly auto-format it
import './style.scss'

import classNames from 'classnames'
import debounce from 'lodash.debounce'
import React, {
  FC,
  useRef,
  useState,
  useEffect,
} from 'react'
import { Col, Row } from 'react-flexbox-grid'
import AsyncSelect from 'react-select/async'
import { useSelector } from 'react-redux'

import { getAllCompaniesForFilterSelector } from 'selectors/company'

export const Select: FC<any> = (
  {
    selectedValue,
    initialSelectedLabel = '',
    selecting = '',
    urlPart1,  // the search query (q) is squished between urlPart1 and urlPart2
    urlPart2 = '',
    getValue,  // function to get the value from an object returned by the API
    getLabel,  // function to get the label from an object returned by the API
    setValue,  // function called when a user selects a value, an object with label and value is passed
    setRawValue,
    label = '',
    error = '',
    withCheckmark = false,
    cacheOptions = true,
  }) => {


  const companiesList = useSelector(getAllCompaniesForFilterSelector)
  const [selectedLabel, setSelectedLabel] = useState('')

  useEffect(() => {
    setSelectedLabel(initialSelectedLabel)
  // eslint-disable-next-line
  }, [initialSelectedLabel]);

  // if we're selecting a company, parent has passed a value but not a label,
  // and user has not actually typed anything to search and get label from api we need to find the label
  if (selecting === 'company' && !selectedLabel && selectedValue) {
    for (const e of companiesList) {if (Number(e.id) === Number(selectedValue) && e.name) {setSelectedLabel(e.name); break;}};}

  function getOptionsFromAPI(q) {
    if (setRawValue) {setRawValue(q)}
    let data: any = []

    // API call
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `${urlPart1}${q === '***' ? '' : q}${urlPart2}`, false)  // last false for synchronous
    xhr.withCredentials = true;
    xhr.onload = function () {
      if   (xhr.readyState !== 4) {return}  // not ready yet
      if   (xhr.status === 200)   {
        data = JSON.parse(xhr.responseText)
        if (data.data) data = data.data
        if (data.results) data = data.results
      }
      else {console.error(xhr.statusText)}
    }
    xhr.send(null)  // no request body since this is a get

    // Construct the options array after API is called
    const result: any = []
    for (const e of data) {
      result.push({
        label: getLabel(e),
        value: getValue(e),
      })}
    return result
  }

  // following two lines are for the width of the combobox
  const containerRef = useRef<any>(null)
  const cssMaxWidth = containerRef.current === null ? '' : containerRef.current.getBoundingClientRect().width + 'px'

  // following line is to integrate debounce, keep as is and don't think much about them
  const loadOptionsDebounced = debounce((q, cb) => {if (q.length >= 3) {cb(getOptionsFromAPI(q));} else {cb([]);}}, 500);

  return (

    <div className='select' style={{ maxWidth: cssMaxWidth }} ref={containerRef}>
      <Row middle='xs' className={classNames({ 'combobox--with-checkmark': withCheckmark })}>

        {label && (<Col xs={12} sm={12} className='label-col label-col-top'><div className='label'>{label}</div></Col>)}

        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          <AsyncSelect
            cacheOptions={cacheOptions}
            loadOptions={loadOptionsDebounced}
            className={`react-select-container`}
            isClearable={true}
            onChange={(e : any) => {setValue(e); setSelectedLabel(e?.label ? e.label : '');}}
            isMulti={false}
            value={selectedValue ? {value: selectedValue, label: selectedLabel} : null}
            classNamePrefix='react-select'
          />
        </Col>
      </Row>

      {error !== '' && <div className='drop-down-input__error'>{error}</div>}
    </div>
  )
}
