export const invoiceCheckOverviewTranslations = {
  acceptInvoiceButton: 'Belegprüfung abschließen',
  denyInvoiceButton: 'Beleg ablehnen',
  reopenInvoiceButton: 'Beleg erneut öffnen',
  editInvoiceCheck: 'Bearbeiten',
  section_header: {
    document: 'Dokument',
    invoice_data: 'Belegdaten',
    invoice_overview: 'Übersicht',
    invoice_positions: 'Belegpositionen',
  },
  section: {
    invoice_data: {
      comment: 'Kommentar',
      company_name: 'Name des Entsorgungspartners (Kreditor)',
      document_number: 'Belegnummer',
      document_date: 'Belegdatum',
      document_type: {
        label: 'Belegart',
        1: 'Rechnung',
        2: 'Gutschrift',
      },
      gross_price: 'Bruttobetrag in €',
      vat: 'Steuersatz',
      created_at: 'Erfassungsdatum',
      created_by: 'Benutzer',
      cancel_reason_text: 'Ablehnungsgrund',
      rejected_at: 'Abgelehnt am',
      rejected_by: 'Abgelehnt von',
      released_at: 'Freigegeben am',
      released_by: 'Freigegeben von',
    },
    invoice_overview: {
      count_invoice_positions: 'Anzahl der Belegpositionen',
      invoice_position: 'Belegposition',
      intermediate_sum: 'Zwischensumme',
      add_vat: 'Zzgl. %{vat}% MwSt.',
      net_sum: 'Gesamtsumme',
      gross_sum: 'Bruttobetrag',
      difference: 'Differenz',
    },
    invoice_positions: {
      add_button: 'Belegposition hinzufügen',
      burden: 'Belastung an EPD',
      burdenCreditLabel: 'Art der Verrechnung',
      category: 'Kategorie',
      categoryText: 'Beschreibung der Dienstleistung',
      container: 'Behälter',
      credit: 'Gutschrift an EPD',
      fraction: 'Abfallart',
      net_price: 'Nettobetrag in €',
      number_of_containers: 'Anzahl der Behälter',
      offer_number: 'Vereinbarungsnummer',
      order_date: 'Auftragsdatum',
      order_number: 'Auftragsnummer',
      order_numbers: 'Auftragsnummern',
      security_group: 'Schutzklasse und Sicherheitsstufe',
      service_address: 'Leistungsadresse',
      service_date: 'Leistungsdatum',
      service_month: 'Leistungsmonat',
      service_type: 'Dienstleistungsart',
      price_model_changed: 'Preismodell (geändert)',
    },
  },
  form: {
    buttons: {},
    description:
      'Hier können Sie den Beleg eines Entsorgers anlegen. Bitte füllen Sie alle erforderlichen ' +
      'Felder aus und laden die benötigten Dokumente hoch.',
    document_number: {
      label: 'Belegnummer',
      placeholder: 'Belegnummer',
      error: 'Ungültige oder leere Belegnummer',
    },
    header: {
      title: 'Beleg erfassen',
    },
    errors: {
      documents: 'Es muss mindestens ein Dokument hochgeladen werden.',
    },
  },
}
