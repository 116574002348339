export const invoiceCheckTranslations = {
  addButton: 'Beleg erfassen',
  exportBatch: {
    exportButton: 'Buchungsstapel Export',
    modal: {
      title: 'Buchungsstapel Export',
    },
  },
  exportInvoiceChecks: {
    exportButton: 'Export',
    modal: {
      title: 'Belegprüfung Export',
    },
  },
  deletePositionModal: {
    title: 'Belegposition entfernen',
    message: 'Möchten Sie diese Belegposition wirklich entfernen?',
  },
  acquisitionForm: {
    buttons: {
      saveAndCheckButton: 'Speichern und direkt prüfen',
      saveAndNewButton: 'Speichern und neuer Beleg',
    },
    description:
      'Hier können Sie den Beleg eines Entsorgers anlegen. Bitte füllen Sie alle erforderlichen ' +
      'Felder aus und laden die benötigten Dokumente hoch.',
    attachments: {
      label: 'Dokumente',
      placeholder: 'Dokumente',
      error: 'Ungültige oder leere Dokumente',
    },
    comment: {
      label: 'Optional: Kommentar',
      placeholder: 'Kommentar',
      error: 'Ungültiger oder zu langer Kommentar',
    },
    company_name: {
      label: 'Name des Entsorgungspartners (Kreditor)',
      noOptionsMessage: 'Kein Partner mit diesem Namen gefunden.',
      placeholder: 'Name des Entsorgungspartners (Kreditor)',
      error: 'Ungültiger oder leerer Partnername',
    },
    empto_external_number: {
      label: 'Kundennummer',
      noOptionsMessage: 'Kein Kunde mit dieser Kundennummer gefunden.',
      placeholder: 'Kundennummer',
      error: 'Ungültige oder leere Kundennummer',
    },
    document_date: {
      label: 'Belegdatum',
      placeholder: 'Belegdatum',
      error: 'Ungültiges oder leeres Belegdatum',
    },
    document_number: {
      label: 'Belegnummer',
      placeholder: 'Belegnummer',
      error: 'Ungültige oder leere Belegnummer',
    },
    document_type: {
      label: 'Belegtyp',
      invoice: 'Rechnung',
      credit_note: 'Gutschrift',
    },
    gross_price: {
      label: 'Bruttobetrag in €',
      placeholder: 'Bruttobetrag in €',
      error: 'Ungültiger oder leerer Bruttobetrag in €',
    },
    vat: {
      label: 'Bitte wählen Sie den Steuersatz für den Beleg aus.',
      summaryLabel: 'Steuersatz',
      error: 'Ungültiger oder leerer Steuersatz',
    },
    search_field: {
      label: 'Suchfeld auswählen',
      placeholder: 'Suchfeld',
      error: 'Ungültiges oder leeres Suchfeld',
    },
    tax_number: {
      label: 'Steuernummer',
      noOptionsMessage: 'Kein Kunde mit dieser Steuernummer gefunden.',
      placeholder: 'Steuernummer',
      error: 'Ungültige oder leere Steuernummer',
    },
    tax_id: {
      label: 'Umsatzsteuer-Identifikationsnummer',
      noOptionsMessage: 'Kein Partner mit dieser Ust.Id. gefunden.',
      placeholder: 'Umsatzsteuer-Identifikationsnummer',
      error: 'Ungültige oder leere Ust.Id.',
    },
    zipcode: {
      label: 'PLZ',
      noOptionsMessage: 'Kein Partner mit dieser PLZ gefunden.',
      placeholder: 'PLZ',
      error: 'Ungültige oder leere PLZ',
    },
    header: {
      title: 'Beleg erfassen',
      titleEdit: 'Belegdaten ändern',
    },
    errors: {
      attachments: 'Es muss mindestens ein Dokument hochgeladen werden.',
    },
  },
  positionsForm: {
    title: 'Belegposition erfassen',
    steps: {
      1: {
        title: 'Leistungsadresse',
        fields: {
          company_name: {
            label: 'Name des Kunden',
            noOptionsMessage: 'Kein Kunde mit diesem Namen gefunden.',
            placeholder: 'Name des Kunden',
            error: 'Ungültiger oder leerer Name des Kunden',
          },
          collection_address: {
            label: 'Ausgewählte Leistungsadresse',
            error: 'Ungültige oder leere Leistungsadresse ausgewählt',
            noResultsText: 'Keine Ergebnisse gefunden',
          },
          order: {
            label: 'Auftragsnummer',
            noOptionsMessage: 'Kein Auftrag mit dieser Nummer gefunden.',
            placeholder: 'Auftragsnummer',
            error: 'Ungültige oder leere Auftragsnummer',
          },
          search_field: {
            label: 'Suchfeld auswählen',
            placeholder: 'Suchfeld',
          },
          zipcode: {
            label: 'PLZ',
            noOptionsMessage: 'Keine Leistungsadresse mit dieser PLZ gefunden.',
            placeholder: 'PLZ',
            error: 'Ungültige oder leere PLZ',
          },
        },
      },
      2: {
        title: 'Offener Auftrag & sonstige Dienstleistung',
        buttons: {
          addRemoveContainer: 'Behälter',
        },
        noPricesFoundInfo:
          'Es konnte kein passender Job aus dem Angebotsassistenten mit gültigen Preisen zu dieser Auswahl gefunden werden.',
        picturesForOrderExistInfo: 'Es existieren Bilder zu dem Auftrag.',
        picturesForOrderExistCallToAction: 'Hier geht es zu den Bildern',
        CancelledOrderInfo: 'Dieser Auftrag wurde storniert.',
        CancelledOrderCallToAction: 'Hier geht es zum Auftrag',
        AlreadyCoveredOrder:
          'Dieser Auftrag wurde bereits auf folgenden Entsorgerbelegen erfasst:',
        fields: {
          avv: {
            label: 'AVV-Nummer',
            noOptionsMessage: 'Keine Ergebnisse gefunden',
            error: 'Ungültige oder leere AVV-Nummer ausgewählt',
            notMatch: 'AVV stimmt nicht mit der Abfallart überein',
          },
          collection_date: {
            error:
              'Ungültgiges oder leeres Datum ausgewählt, Datum muss NACH Stelldatum liegen',
          },
          container: {
            label: 'Behälter',
            noResultsText: 'Kein Behälter gefunden',
            error: 'Ungültiger oder leerer Behälter ausgewählt',
          },
          delivery_date: {
            error: 'Ungültiges oder leeres Datum ausgewählt',
          },
          disposal_cost_ton: {
            label: 'Preis pro Tonne oder Behälter in €',
            error:
              'Ungültiger oder leerer Preis pro Tonne oder Behälter ausgewählt',
          },
          disposal_proof_number: {
            error: 'Entsorgungsnachweisnummer muss 12-stellig sein.',
          },
          facility: {
            error: 'Anlage muss gefüllt sein.',
          },
          fraction: {
            label: 'Abfallart',
            noResultsText: 'Keine Abfallart gefunden',
            error: 'Ungültige oder leere Abfallart ausgewählt',
            notMatch: 'Abfallart stimmt nicht mit der AVV überein',
          },
          number_of_containers: {
            label: 'Anzahl',
            error: 'Ungültige oder leere Anzahl ausgewählt',
          },
          offer: {
            label: 'Vereinbarung',
            noResultsText: 'Keine Vereinbarung gefunden',
            error: 'Ungültige oder leere Vereinbarungsnummer ausgewählt',
          },
          deliveryMonth: {
            label: 'Leistungsmonat',
          },
          security_group: {
            label: 'Schutzklasse und Sicherheitsstufe',
            error: 'Ungültige Schutzklasse und Sicherheitsstufe ausgewählt',
          },
          order_type: {
            open_order: {
              label: 'Offener Auftrag',
              noResultsText: 'Offener Auftrag',
            },
            other_service: {
              label: 'Sonstige Dienstleistung',
              noResultsText: 'Sonstige Dienstleistung',
            },
            rent: {
              label: 'Mietbeleg',
              noResultsText: 'Mietbeleg',
            },
            label: 'Auftragsart',
            error: 'Ungültige oder leere Auftragsart ausgewählt',
          },
          order: {
            label: 'Offener Auftrag',
            noResultsText: 'Kein Auftrag gefunden',
            error: 'Ungültige oder leere Auftragsnummer ausgewählt',
            actionLinkLabel: 'ältere Aufträge anzeigen',
          },
          otherService: {
            category: {
              label: 'Kategorie',
              deadhead: 'Leerfahrt',
              placementPermit: 'Stellgenehmigung',
              rent: 'Miete',
              streetCharge: 'Maut',
              sortingCosts: 'Sortierkosten',
              takeoverSlip: 'Übernahmeschein',
              discount: 'Rabatt',
              other: 'Sonstiges',
            },
            categoryText: {
              label: 'Beschreibung der Dienstleistung',
              error: 'Bitte geben Sie eine Beschreibung der Dienstleistung an',
            },
            netPrice: {
              label: 'Nettobetrag in €',
              error: 'Bitte geben Sie einen Nettopreis an',
              burdenCreditLabel: 'Art der Verrechnung',
              burden: 'Belastung an EPD',
              credit: 'Gutschrift an EPD',
            },
          },
          rent_price_container_month: {
            label: 'Miete pro Behälter in €',
            error: 'Ungültige oder leere Miete pro Behälter ausgewählt',
          },
          transport_price_piece: {
            label: 'Transportpreis pro Behälter in €',
            error:
              'Ungültiger oder leerer Transportpreis pro Behälter ausgewählt',
          },
          transportation_company: {
            error: 'Beförderer muss gefüllt sein.',
          },
          weight_or_number: {
            error: 'Ungültige oder leere Option ausgewählt',
          },
          errors: {
            headline: 'Bitte überprüfen Sie folgende Daten:',
          },
          label: {
            avv: 'AVV-Nummer',
            collectionAddress: 'Leistungsadresse',
            container: 'Behälter',
            deliveryDate: 'Lieferdatum',
            disposalCostContainer:
              'Transport- und Entsorgungspreis (€ pro Behälter)',
            disposalCostTon: 'Preis pro Tonne oder Behälter in €',
            fraction: 'Abfallart',
            netPrice: 'Sonstige Dienstleistung / Mietbeleg (NettoPreis)',
            nonFieldErrors: 'Allgemeine Fehler',
            numberOfContainers: 'Anzahl',
            offer: 'Vereinbarung',
            openOrder: 'Offener Auftrag',
            order: 'Offener Auftrag',
            orderType: 'Auftragsart',
            otherServices: 'Sonstige Dienstleistung',
            rentPriceContainerMonth: 'Miete pro Behälter in €',
            securityGroup: 'Schutzklasse und Sicherheitsstufe',
            transportPricePiece: 'Transportpreis pro Behälter in €',
            weight: 'Ungültiges Gewicht eingegeben.',
            weightReceipt: 'Ungültiges Format für Wiegescheinnummer.',
          },
        },
      },
      3: {
        buttons: {
          saveAndBack: 'Speichern und zurück zur Übersicht',
          deletePosition: 'Belegposition löschen',
        },
        header:
          'Bitte überprüfen Sie Ihre Angaben und gleichen den Nettobetrag des Auftrags mit dem des Belegs ab.',
        fields: {
          net_price: 'Nettobetrag in €',
        },
        title: 'Summe der Belegpositionen',
      },
    },
  },
  rejectForm: {
    header: 'Beleg ablehnen?',
    description:
      'Mit der Bestätigung wird der Beleg abgelehnt. Es können dann keine weiteren Änderungen ' +
      'vorgenommen werden.',
    cancelReasonTextLabel: 'Ablehnungsgrund',
    cancelReasonTextPlaceholder:
      'Bitte geben Sie hier einen Grund für die Ablehnung an',
    acceptRejectionButton: 'Bestätigen',
  },
  releaseForm: {
    header: 'Beleg freigeben?',
    description:
      'Mit der Bestätigung wird der Beleg freigegeben und gebucht. ' +
      'Er wird automatisch mit einem der nächsten Bezahlläufe bezahlt.',
    acceptReleaseButton: 'Bestätigen',
  },
  status: {
    accepted: 'Akzeptiert',
    cancelled: 'Storniert',
    pending: 'In Prüfung',
    protested: 'Reklamiert',
    rejected: 'Abgelehnt',
    reopened: 'Erneut geöffnet',
  },
  table: {
    actions: 'Aktionen',
    attachments: 'Dokumente',
    documentNumber: 'Belegnummer',
    grossPrice: 'Bruttobetrag',
    partnerCompany: 'Partner',
    reviewedAt: 'Geprüft am',
    reviewedBy: 'Geprüft von',
    status: 'Status',
    tooltip: {
      view: 'ansehen',
      check: 'prüfen',
    },
    uploadedAt: 'Hochgeladen am',
    uploadedBy: 'Hochgeladen von',
  },
}
