import './style.scss'

import { useFormikContext } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import Spinner from 'react-spinkit'

import { getAddressListForCompany } from 'actions/address'
import {
  getCompany,
  getMaklerPremiumCompanies,
  resetMaklerPremiumCompanies,
} from 'actions/company'
import {
  getFrameworkContract,
  resetFrameworkContract,
  resetPriceComparisonTable,
} from 'actions/maklerpremium'
import { getAcceptedOffers, resetAcceptedOffers } from 'actions/offer'
import { getCompanyUsers } from 'actions/user'
import { resetZipcode } from 'actions/zipCode'
import { AddressForm } from 'components/common/AddressForm'
import { ZipcodeFormField } from 'components/common/AddressForm/ZipcodeFormField'
import { DismissableInfo } from 'components/common/DismissableInfo'
import { DropDownInput } from 'components/common/DropDownInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { RadioPanel } from 'components/common/RadioPanel'
import { RadioPanelGroup } from 'components/common/RadioPanelGroup'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import {
  ADDRESS_STATUS,
  COMPANY_ROLE,
  COMPANY_STATUS,
} from 'components/company/constants'
import {
  ADD_ADDRESS_OPTION,
  OFFER_ORDER_TYPE,
  OFFER_TIME_OF_DAY,
} from 'components/inquiry/constants'
import { OFFER_STATUS } from 'components/offer/constants'
import { APP_CONSTANTS } from 'constants/app'
import { UserPermission } from 'constants/user'
import { getAddressListForCompanySelector } from 'selectors/address'
import {
  getCompanySelector,
  getMaklerPremiumCompaniesSelector,
} from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'
import { getMyOffersSelector } from 'selectors/offer'
import { Select } from 'components/Select/Select'

import {
  CreateMaklerPremiumOfferContext,
  CUSTOMER_TYPE,
  MaklerPremiumOfferValues,
} from '../CreateMaklerPremiumOfferFormSteps'

import { getSelectedCompany } from './helpers'

export const CustomerInfoForm: FC = () => {
  const { errors, handleChange, resetForm, setFieldValue, setValues, values } =
    useFormikContext<MaklerPremiumOfferValues>()

  const location = useLocation<{ company: CompanySmall }>()
  const dispatch = useDispatch()
  const addressList = useSelector(getAddressListForCompanySelector)
  const customers = useSelector(getMaklerPremiumCompaniesSelector)
  const agreementsList = useSelector(
    getMyOffersSelector,
  ) as unknown as AcceptedOffer[] // Workaround to proper type-hints for disposer_name property

  const isLoading = {
    addressList: useSelector(
      createLoadingSelector(['GET_ADDRESSES_FOR_COMPANY']),
    ),
    acceptedAgreements: useSelector(
      createLoadingSelector(['GET_ACCEPTED_OFFERS']),
    ),
  }

  const [addressOptions, setAddressOptions] = useState<Option[]>([])
  const [currentSelectedAddress, setCurrentSelectedAddress] = useState<Option>()
  const [sumOfUnpaidInvoices, setSumOfUnpaidInvoices] = useState<
    number | string
  >()
  const [noOfUnpaidInvoices, setNoOfUnpaidInvoices] = useState<number>()
  const [creditLimitCommentDismissed, setCreditLimitCommentDismissed] =
    useState(false)
  const [showActiveAgreementsInfo, setShowActiveAgreementsInfo] =
    useState(false)
  const [acceptedOffersRequested, setAcceptedOffersRequested] = useState(false)
  const [openAddAddressModal, setOpenAddAddressModal] = useState<boolean>(false)

  // const company = location.state && location.state.company
  const company = useSelector(getCompanySelector)

  const idAddressModalHeadline = uniqueId()
  const { stepStatus, saveStep } = useContext(CreateMaklerPremiumOfferContext)
  const searchFields = [
    {
      optionLabel: I18n.t(
        'createMaklerPremiumOfferPageTranslations.steps.1.fields.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'createMaklerPremiumOfferPageTranslations.steps.1.fields.empto_external_number.label',
      ),
      optionValue: 'empto_external_number',
    },
    {
      optionLabel: I18n.t(
        'createMaklerPremiumOfferPageTranslations.steps.1.fields.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
  ]

  /**
   * Check to determine the status to show the save button.
   */
  const existingCustomerAndValidCollectionAddress = useMemo(
    () =>
      values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER &&
      !!values.collection_address &&
      !!values.existing_customer,
    [values.customer_type, values.collection_address, values.existing_customer],
  )

  /**
   * Check to determine the status to show the save button.
   */
  const newCustomerAndValidZipCode = useMemo(
    () =>
      values.customer_type === CUSTOMER_TYPE.NEW_CUSTOMER &&
      !!values.zipcode &&
      !errors.zipcode,
    [values.customer_type, values.zipcode, errors.zipcode],
  )

  /**
   * Check to determine the status to show the save button.
   *
   * Check if active agreements for selected address was requested and available and the DismissableInfo was accepted or
   * active agreements for selected address requested and was not found
   *
   * In both situations the save button can be shown for this case
   */
  const activeAgreementsStatus = useMemo(
    () =>
      !showActiveAgreementsInfo &&
      acceptedOffersRequested &&
      !isLoading.acceptedAgreements,
    [
      showActiveAgreementsInfo,
      acceptedOffersRequested,
      isLoading.acceptedAgreements,
    ],
  )

  const handleResetAcceptedOffers = useCallback(() => {
    dispatch(resetAcceptedOffers())
    setShowActiveAgreementsInfo(false)
    setAcceptedOffersRequested(false)
  }, [dispatch])

  const handleResetFormOnCustomerChange = useCallback(
    (customerType, existingCustomer, existingCustomerStatus, discount) => {
      setFieldValue('existing_customer_status', existingCustomerStatus)
      handleResetAcceptedOffers()
      dispatch(resetFrameworkContract())
      return {
        // Step 1: CustomerInfo
        collection_address: undefined,
        customer_type: customerType,
        existing_customer: existingCustomer,
        existing_customer_status: existingCustomerStatus,
        search_field: 'company_name',
        zipcode: undefined,
        // Step 2: Fraction & Container / Pricing
        fraction: undefined,
        container: undefined,
        auto_offer: undefined,
        offer_end_price: undefined,
        discount,
        fine_fraction: undefined,
        number_of_containers: 1,
        position_on_public_ground: undefined,
        // -- Insert values here
        // Step 3: Create Offer
        order_type: OFFER_ORDER_TYPE.TYPE_ONE_TIME,
        delivery_date: '',
        collection_date: '',
        time_of_day_delivery: String(OFFER_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY),
        time_of_day_collection: String(OFFER_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY),
        turn_begin: '',
        turn_end: '',
        interval: undefined,
        interval_weekday_first: undefined,
        interval_weekday_second: undefined,
        customer_email: '',
        email_note: undefined,
        customer_subtype: undefined,
        company_name: undefined,
        street: undefined,
        house_number: undefined,
        city: undefined,
        gender: undefined,
        first_name: undefined,
        last_name: undefined,
        tax_id: undefined,
        tax_number: undefined,
        runtime_of_inquiry: '',
        different_invoicing_address: false,
        invoice_recipient: '',
        invoice_street: '',
        invoice_house_number: '',
        invoice_zipcode: '',
        invoice_city: '',
        is_compulsory_offer: false,
        is_order_of_framework_contract: false,
        non_field_errors: undefined, // dummy value for non-field-errors, DO NOT USE in Form
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleResetAcceptedOffers],
  )

  const handleChangeCompany = useCallback(() => {
    if (company?.id) {
      setValues(
        handleResetFormOnCustomerChange(
          CUSTOMER_TYPE.RETURNING_CUSTOMER,
          company!.id,
          company!.status,
          company!.permanent_discount,
        ),
        false,
      )
      setCreditLimitCommentDismissed(false)
      setSumOfUnpaidInvoices(company.credit_limit?.sum_of_unpaid_invoices)
      setNoOfUnpaidInvoices(company.credit_limit?.no_of_unpaid_invoices)
    }
  }, [handleResetFormOnCustomerChange, setValues, company])

  const handleChangeAddress = event => {
    handleResetAcceptedOffers()
    setCurrentSelectedAddress(
      addressOptions.find(
        option => option.value === Number(event.target.value),
      ),
    )
    if (event.target.value === ADD_ADDRESS_OPTION.value) {
      setFieldValue('collection_address', '')
      dispatch(getCompanyUsers(values.existing_customer, true))
      setOpenAddAddressModal(true)
      dispatch(resetZipcode())
    } else {
      setFieldValue('collection_address', Number(event.target.value))
      const address = addressList.find(
        _address => Number(_address.id) === Number(event.target.value),
      )
      if (address) {
        dispatch(
          getAcceptedOffers(null, {
            customer_company: values.existing_customer,
            page_size: 1000,
            agreement_status: OFFER_STATUS.STATUS_ACCEPTED,
            collection_address: address.id,
          }),
        )
        setFieldValue('zipcode', address.zipcode)
      }
    }
  }

  const handleChangeRadioPanel = useCallback(
    (value: string) => {
      resetForm()
      setFieldValue('customer_type', Number(value))
    },
    [resetForm, setFieldValue],
  )

  useEffect(() => {
    handleChangeCompany()
    if (company?.id) {
      dispatch(
        getMaklerPremiumCompanies(0, {
          id_slug: `${company.id},${company.slug}`,
          makler_premium_role: COMPANY_ROLE.WASTE_PRODUCER,
          has_maklerpremium_agreements: false,
          has_empto_agreements: false,
          page_size: 1,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  useEffect(() => {
    if (location.state && location.state.company) {
      dispatch(
        getCompany({ id: location.state.company.id, expand: 'credit_limit' }),
      )
      handleChangeCompany()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (values.existing_customer) {
      dispatch(
        getAddressListForCompany(values.existing_customer, {
          status: ADDRESS_STATUS.STATUS_ACTIVE,
        }),
      )
    }
  }, [dispatch, values.existing_customer])

  useEffect(() => {
    const options = addressList.map(address => ({
      label: address.display_name,
      value: Number(address.id),
    })) as Option[]
    options.unshift({
      label: I18n.t(ADD_ADDRESS_OPTION.label),
      value: ADD_ADDRESS_OPTION.value,
    })
    setAddressOptions(options)
  }, [addressList])

  useEffect(() => {
    if (values.collection_address) {
      setCurrentSelectedAddress(
        addressOptions.find(
          option => option.value === values.collection_address,
        ),
      )
    }
  }, [addressOptions, values.collection_address])

  useEffect(() => {
    dispatch(resetPriceComparisonTable())
  }, [dispatch, values.zipcode])

  useEffect(() => {
    if (agreementsList && agreementsList.length > 0) {
      setShowActiveAgreementsInfo(true)
    }
    setAcceptedOffersRequested(true)
  }, [agreementsList])

  return (
    <>
      <div className='maklerpremium-offerform__step'>
        <RadioPanelGroup
          initialSelection={`${values.customer_type}`}
          columns={2}
          onChange={value => {
            handleChangeRadioPanel(value)
            handleResetAcceptedOffers()
          }}
        >
          <RequiredPermissions
            requiredPermissions={[
              UserPermission.ADD_MAKLER_PREMIUM_OFFER_FOR_EXISTING_USER,
            ]}
          >
            <RadioPanel
              ariaLabel={I18n.t('general.button.select')}
              buttonLabel={I18n.t('general.button.select')}
              showButton
              value={`${CUSTOMER_TYPE.RETURNING_CUSTOMER}`}
            >
              <Headline
                color={HEADLINE_COLOR.DARK_GRAY}
                fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                headlineStyle={HEADLINE_STYLE.H5}
                noMargin
                tag={HEADLINE_TAG.H3}
              >
                <Translate value='createMaklerPremiumOfferPageTranslations.steps.1.radiopanel.returningCustomer' />
              </Headline>
            </RadioPanel>
          </RequiredPermissions>
          <RadioPanel
            ariaLabel={I18n.t('general.button.select')}
            buttonLabel={I18n.t('general.button.select')}
            showButton
            value={`${CUSTOMER_TYPE.NEW_CUSTOMER}`}
          >
            <Headline
              color={HEADLINE_COLOR.DARK_GRAY}
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
              headlineStyle={HEADLINE_STYLE.H5}
              noMargin
              tag={HEADLINE_TAG.H3}
            >
              <Translate value='createMaklerPremiumOfferPageTranslations.steps.1.radiopanel.newCustomer' />
            </Headline>
          </RadioPanel>
        </RadioPanelGroup>

        <div className='maklerpremium-offerform__grid'>
          {values.customer_type === CUSTOMER_TYPE.NEW_CUSTOMER && (
            <div className='maklerpremium-offerform__column-2'>
              <ZipcodeFormField
                withCheckmark
                showCheckmark={values.zipcode ? !errors.zipcode : false}
                showRequiredDot={false}
              />
            </div>
          )}
          {values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER && (
            <>
              <div className='maklerpremium-offerform__column-2'>
                <DropDownInput
                  choices={searchFields}
                  label={I18n.t(
                    'createMaklerPremiumOfferPageTranslations.steps.1.fields.search_field.label',
                  )}
                  name='search_field'
                  onChange={e => {
                    setFieldValue('existing_customer', '')
                    setFieldValue('zipcode', '')
                    dispatch(resetMaklerPremiumCompanies()) // Reset Filtered results
                    handleChange(e)
                  }}
                  showCheckmark={!!values.search_field}
                  value={values.search_field}
                  withCheckmark
                />
              </div>
              <div className='maklerpremium-offerform__column-2'>
                {/*prettier-ignore*/}
                <Select
                  urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/makler-premium-companies/?${values.search_field}=`}
                  urlPart2='&maklerpremium_role=1&page_size=1000&page=1'
                  getValue={(e) => e.id}
                  getLabel={(e) => e.id === 0 ? e.name : `${e.main_address_object.zipcode}, ${e.name}, ${e.empto_external_number}`}
                  label={I18n.t(`createMaklerPremiumOfferPageTranslations.steps.1.fields.${values.search_field}.label`)}
                  setValue={e => {
                    if (e?.value) {
                      dispatch(getCompany({ id: e.value, expand: 'credit_limit'}))
                    }
                    else {setFieldValue('selected_company', undefined);}
                  }}
                  selectedValue={values.existing_customer}
                  initialSelectedLabel={values.existing_customer ? getSelectedCompany(Number(values.existing_customer), customers)?.name : ''}
                  selecting={'company'}
                  error={errors.existing_customer}
                />
                {!creditLimitCommentDismissed && !!noOfUnpaidInvoices && (
                  <div className='error-warning'>
                    <DismissableInfo
                      title={I18n.t('general.information')}
                      onClick={() => setCreditLimitCommentDismissed(true)}
                      text={
                        <Translate
                          value='createMaklerPremiumOfferPageTranslations.steps.1.fields.creditLimitInfo'
                          sumOfUnpaidInvoices={String(
                            sumOfUnpaidInvoices,
                          ).replace('.', ',')}
                          noOfUnpaidInvoices={noOfUnpaidInvoices}
                          companyName={
                            getSelectedCompany(
                              Number(values.existing_customer),
                              customers,
                            )?.name
                          }
                          dangerousHTML
                        />
                      }
                      buttonText={I18n.t('general.button.submit')}
                    />
                  </div>
                )}
              </div>
              {values.existing_customer && (
                <div className='maklerpremium-offerform__column-2'>
                  <StaticCombobox
                    label={I18n.t(
                      'createInquiryTranslations.form.addressAndDeliveryFormGroup.label.collectionAddress',
                    )}
                    dataTestId='address-input'
                    subLabel={I18n.t(
                      'createInquiryTranslations.form.addressAndDeliveryFormGroup.subLabel.collectionAddress',
                    )}
                    isLoading={isLoading.addressList}
                    name='collection_address'
                    noResultsText={I18n.t(
                      'createInquiryTranslations.form.addressAndDeliveryFormGroup.noInputResults.collectionAddress',
                    )}
                    options={addressOptions}
                    onSelectionChange={handleChangeAddress}
                    placeholder={I18n.t(
                      'createInquiryTranslations.form.addressAndDeliveryFormGroup.placeholder.collectionAddress',
                    )}
                    selectedOption={
                      values.collection_address
                        ? currentSelectedAddress
                        : undefined
                    }
                    withCheckmark
                    showCheckmark={!!values.collection_address}
                  />
                </div>
              )}
              {isLoading.acceptedAgreements && (
                <div className='maklerpremium-offerform__column-2'>
                  <div className='uk-flex uk-flex-center uk-margin-large-top'>
                    <Spinner name='circle' fadeIn='none' />
                  </div>
                </div>
              )}
              {showActiveAgreementsInfo && (
                <div className='error-warning'>
                  <DismissableInfo
                    title={I18n.t('general.information')}
                    onClick={() => setShowActiveAgreementsInfo(false)}
                    text={
                      <>
                        <Translate
                          value='createMaklerPremiumOfferPageTranslations.steps.1.fields.activeAgreementsInfo'
                          dangerousHTML
                        />
                        <Link
                          to={
                            `/agreement?company=${values.existing_customer}&address=${values.collection_address}` +
                            `&status=${OFFER_STATUS.STATUS_ACCEPTED}&role=${COMPANY_ROLE.WASTE_PRODUCER}`
                          }
                          target='_blank'
                        >
                          <Translate
                            value='createMaklerPremiumOfferPageTranslations.steps.1.fields.activeAgreementsInfoLink'
                            agreementCount={agreementsList.length}
                            dangerousHTML
                          />
                        </Link>
                        <br />
                        <Translate
                          value='createMaklerPremiumOfferPageTranslations.steps.1.fields.activeAgreementsDisposer'
                          dangerousHTML
                        />
                        {Array.from(
                          new Set(
                            agreementsList.map(
                              agreement => agreement.disposer_name,
                            ),
                          ),
                        ).join(', ')}
                      </>
                    }
                    buttonText={I18n.t('general.button.submit')}
                  />
                </div>
              )}
            </>
          )}
          {((existingCustomerAndValidCollectionAddress &&
            activeAgreementsStatus) ||
            newCustomerAndValidZipCode) && (
            <div className='maklerpremium-offerform__column-2'>
              <GoToNextFormStepButton
                buttonText={
                  stepStatus === STEP_STATUS.EDITING
                    ? I18n.t('general.button.save')
                    : I18n.t('createInquiryTranslations.form.button.nextStep')
                }
                onClick={() => {
                  if (
                    values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER
                  ) {
                    dispatch(
                      getFrameworkContract({
                        company: values.existing_customer!,
                        service_addresses: values.collection_address!,
                      }),
                    )
                  } else {
                    dispatch(resetFrameworkContract())
                  }
                  saveStep()
                }}
                isDisabled={
                  values.existing_customer_status
                    ? values.existing_customer_status ===
                      COMPANY_STATUS.STATUS_INACTIVE_BY_EPD
                    : false
                }
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        ariaDescribedBy={idAddressModalHeadline}
        isOpen={openAddAddressModal}
        onClose={() => setOpenAddAddressModal(false)}
      >
        <ModalHeader
          onClose={() => setOpenAddAddressModal(false)}
          title={I18n.t('addressForm.header.title')}
          titleId={idAddressModalHeadline}
        />

        <AddressForm
          onCancel={() => setOpenAddAddressModal(false)}
          onSuccess={(address: Address) => {
            setFieldValue('collection_address', address.id)
            setFieldValue('zipcode', address.zipcode)
            setOpenAddAddressModal(false)
            setAcceptedOffersRequested(true)
          }}
          sendUserInfo={{
            company: values.existing_customer,
          }}
        />
      </Modal>
    </>
  )
}
